var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "merchantHouseInfo-container" },
    [
      _c(
        "form-panel",
        { ref: "formPanel", attrs: { form: _vm.form, footerShow: false } },
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "房号基础信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属项目",
                        prop: "communityId",
                        rules: [
                          {
                            required: true,
                            message: "请选择所属项目",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              placeholder: "请选择所属项目",
                              disabled: !_vm.isAdd || _vm.showMerchantsInfo,
                              width: _vm.width,
                            },
                            on: { onChange: _vm.communityChange },
                            model: {
                              value: _vm.form.communityId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "communityId", $$v)
                              },
                              expression: "form.communityId",
                            },
                          },
                          "v-select2",
                          _vm.communityParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "房号信息" } }, [
                    _c("div", { staticClass: "elform-content-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "elform-item-wrapper" },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c("autocomplete", {
                                style: { width: _vm.width + "px" },
                                attrs: {
                                  disabled: _vm.showMerchantsInfo,
                                  "fetch-suggestions": _vm.blockNameSearch,
                                  placeholder: "苑",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.blockName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "blockName", $$v)
                                  },
                                  expression: "form.blockName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "elform-item-wrapper" },
                        [
                          _c("span", { staticClass: "mark-color" }, [
                            _vm._v("*"),
                          ]),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "buildingNumber",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入楼幢/停车场",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-input", {
                                attrs: {
                                  disabled: _vm.showMerchantsInfo,
                                  placeholder: "楼幢/停车场",
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.buildingNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "buildingNumber", $$v)
                                  },
                                  expression: "form.buildingNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "elform-item-wrapper" },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c("v-input", {
                                attrs: {
                                  disabled: _vm.showMerchantsInfo,
                                  placeholder: "单元/车区",
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.unit,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "unit", $$v)
                                  },
                                  expression: "form.unit",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "elform-item-wrapper" },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c("v-input", {
                                attrs: {
                                  disabled: _vm.showMerchantsInfo,
                                  placeholder: "室/车位/车库",
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.room,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "room", $$v)
                                  },
                                  expression: "form.room",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "250px",
                        label: "是否开启和第三方系统房号映射",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          disabled: _vm.showMerchantsInfo,
                          width: 300,
                          maxlength: 60,
                        },
                        model: {
                          value: _vm.form.newwinHouseNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "newwinHouseNum", $$v)
                          },
                          expression: "form.newwinHouseNum",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属楼层",
                        prop: "floor",
                        rules: [
                          {
                            required: true,
                            message: "请输入所属楼层",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input-number", {
                        attrs: {
                          disabled: _vm.showMerchantsInfo,
                          min: -99,
                          max: 999,
                          placeholder: "请输入所属楼层",
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.floor,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "floor", $$v)
                          },
                          expression: "form.floor",
                        },
                      }),
                      _c("span", { staticClass: "tip-text" }, [
                        _vm._v("层/楼"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "是否虚拟",
                        prop: "isVirtual",
                        rules: [
                          {
                            required: true,
                            message: "请选择是否虚拟",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          disabled: _vm.showMerchantsInfo,
                          options: _vm.isVirtualOps,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.isVirtual,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isVirtual", $$v)
                          },
                          expression: "form.isVirtual",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "建筑类型",
                        prop: "buildType",
                        rules: [
                          {
                            required: true,
                            message: "请选择建筑类型",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          disabled: _vm.showMerchantsInfo,
                          options: _vm.buildTypeOps,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.buildType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "buildType", $$v)
                          },
                          expression: "form.buildType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "房屋类型",
                        prop: "houseType",
                        rules: [
                          {
                            required: true,
                            message: "请选择房屋类型",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          disabled: _vm.showMerchantsInfo,
                          options: _vm.houseTypeOps,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.houseType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "houseType", $$v)
                          },
                          expression: "form.houseType",
                        },
                      }),
                      _c("span", { staticClass: "tip-text" }, [
                        _vm._v(
                          "注：当房屋类型为住宅、公寓、排屋、别墅、商铺、办公用房、经营用房时，才能显示在App中，被用户关联"
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "房号状态" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "房屋状态", required: "" } },
                    [
                      _c("div", { staticClass: "elform-content-wrapper" }, [
                        _c(
                          "div",
                          { staticClass: "elform-item-wrapper" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "roomStatus",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择房屋状态",
                                      trigger: "change",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("v-select", {
                                  attrs: {
                                    disabled: _vm.showMerchantsInfo,
                                    width: _vm.width,
                                    options: _vm.roomStatusOps,
                                  },
                                  model: {
                                    value: _vm.form.roomStatus,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "roomStatus", $$v)
                                    },
                                    expression: "form.roomStatus",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "elform-item-wrapper" },
                          [
                            _c("span", { staticClass: "mark-color" }, [
                              _vm._v("*"),
                            ]),
                            _c("span", { staticClass: "mark-text" }, [
                              _vm._v("是否可售："),
                            ]),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "canSell",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择是否可售",
                                      trigger: "change",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("v-select", {
                                  attrs: {
                                    disabled: _vm.showMerchantsInfo,
                                    width: _vm.width,
                                    options: _vm.saleTypeOps,
                                  },
                                  model: {
                                    value: _vm.form.canSell,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "canSell", $$v)
                                    },
                                    expression: "form.canSell",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.form.canSell === 1
                          ? _c(
                              "div",
                              { staticClass: "elform-item-wrapper" },
                              [
                                _c("span", { staticClass: "mark-color" }, [
                                  _vm._v("*"),
                                ]),
                                _c("span", { staticClass: "mark-text" }, [
                                  _vm._v("出售状态："),
                                ]),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "isSell",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择出售状态",
                                          trigger: "change",
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        disabled: _vm.showMerchantsInfo,
                                        width: _vm.width,
                                        options: _vm.saleStatusOps,
                                      },
                                      model: {
                                        value: _vm.form.isSell,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "isSell", $$v)
                                        },
                                        expression: "form.isSell",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "使用状态", required: "" } },
                    [
                      _c("div", { staticClass: "elform-content-wrapper" }, [
                        _c(
                          "div",
                          { staticClass: "elform-item-wrapper" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "usingStatus",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择使用状态",
                                      trigger: "change",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("v-select", {
                                  attrs: {
                                    disabled: _vm.showMerchantsInfo,
                                    width: _vm.width,
                                    options: _vm.usingStatusOps,
                                  },
                                  model: {
                                    value: _vm.form.usingStatus,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "usingStatus", $$v)
                                    },
                                    expression: "form.usingStatus",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "elform-item-wrapper" },
                          [
                            _c("span", { staticClass: "mark-color" }, [
                              _vm._v("*"),
                            ]),
                            _c("span", { staticClass: "mark-text" }, [
                              _vm._v("是否可租："),
                            ]),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "canLease",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择是否可租",
                                      trigger: "change",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("v-select", {
                                  attrs: {
                                    disabled: _vm.showMerchantsInfo,
                                    width: _vm.width,
                                    options: _vm.rentTypeOps,
                                  },
                                  model: {
                                    value: _vm.form.canLease,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "canLease", $$v)
                                    },
                                    expression: "form.canLease",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.form.canLease === 1
                          ? _c(
                              "div",
                              { staticClass: "elform-item-wrapper" },
                              [
                                _c("span", { staticClass: "mark-color" }, [
                                  _vm._v("*"),
                                ]),
                                _c("span", { staticClass: "mark-text" }, [
                                  _vm._v("出租状态："),
                                ]),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "isLease",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择出租状态",
                                          trigger: "change",
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        disabled: _vm.showMerchantsInfo,
                                        width: _vm.width,
                                        options: _vm.rentStatusOps,
                                      },
                                      model: {
                                        value: _vm.form.isLease,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "isLease", $$v)
                                        },
                                        expression: "form.isLease",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "其他信息" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "房屋户型" } },
                    [
                      _c("v-input", {
                        attrs: {
                          disabled: _vm.showMerchantsInfo,
                          placeholder: "请输入房屋户型",
                          width: _vm.width,
                          maxlength: 30,
                        },
                        model: {
                          value: _vm.form.roomStyle,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "roomStyle", $$v)
                          },
                          expression: "form.roomStyle",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.isAdd
                    ? _c("el-form-item", { attrs: { label: "欠费金额" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.form.propertyFeeArrearage)),
                        ]),
                      ])
                    : _vm._e(),
                  !_vm.isAdd
                    ? _c("el-form-item", { attrs: { label: "缴纳日期" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.propertyFeePaidToDate))]),
                      ])
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "计费面积",
                        prop: "chargingArea",
                        rules: [
                          {
                            required: true,
                            message: "请输入计费面积",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-input-number",
                        _vm._b(
                          {
                            attrs: {
                              disabled: _vm.showMerchantsInfo,
                              placeholder: "请输入计费面积",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.chargingArea,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "chargingArea", $$v)
                              },
                              expression: "form.chargingArea",
                            },
                          },
                          "v-input-number",
                          _vm.areaNumParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "套内面积" } },
                    [
                      _c(
                        "v-input-number",
                        _vm._b(
                          {
                            attrs: {
                              disabled: _vm.showMerchantsInfo,
                              placeholder: "请输入套内面积",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.innerArea,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "innerArea", $$v)
                              },
                              expression: "form.innerArea",
                            },
                          },
                          "v-input-number",
                          _vm.areaNumParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分摊面积" } },
                    [
                      _c(
                        "v-input-number",
                        _vm._b(
                          {
                            attrs: {
                              disabled: _vm.showMerchantsInfo,
                              placeholder: "请输入分摊面积",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.publicArea,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "publicArea", $$v)
                              },
                              expression: "form.publicArea",
                            },
                          },
                          "v-input-number",
                          _vm.areaNumParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "辅助面积" } },
                    [
                      _c(
                        "v-input-number",
                        _vm._b(
                          {
                            attrs: {
                              disabled: _vm.showMerchantsInfo,
                              placeholder: "请输入辅助面积",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.assistChargingArea,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "assistChargingArea", $$v)
                              },
                              expression: "form.assistChargingArea",
                            },
                          },
                          "v-input-number",
                          _vm.areaNumParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "花园面积" } },
                    [
                      _c(
                        "v-input-number",
                        _vm._b(
                          {
                            attrs: {
                              disabled: _vm.showMerchantsInfo,
                              placeholder: "请输入花园面积",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.gardenArea,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "gardenArea", $$v)
                              },
                              expression: "form.gardenArea",
                            },
                          },
                          "v-input-number",
                          _vm.areaNumParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "地下室面积" } },
                    [
                      _c(
                        "v-input-number",
                        _vm._b(
                          {
                            attrs: {
                              disabled: _vm.showMerchantsInfo,
                              placeholder: "请输入地下室面积",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.undergroundArea,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "undergroundArea", $$v)
                              },
                              expression: "form.undergroundArea",
                            },
                          },
                          "v-input-number",
                          _vm.areaNumParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "统计面积" } },
                    [
                      _c(
                        "v-input-number",
                        _vm._b(
                          {
                            attrs: {
                              disabled: _vm.showMerchantsInfo,
                              placeholder: "请输入统计面积",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.statisticalArea,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "statisticalArea", $$v)
                              },
                              expression: "form.statisticalArea",
                            },
                          },
                          "v-input-number",
                          _vm.areaNumParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "排序",
                        prop: "sort",
                        rules: [
                          {
                            required: true,
                            message: "请输入排序",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input-number", {
                        attrs: {
                          disabled: _vm.showMerchantsInfo,
                          min: 0,
                          max: 999,
                          placeholder: "请输入排序",
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sort", $$v)
                          },
                          expression: "form.sort",
                        },
                      }),
                      _c("span", { staticClass: "tip-text" }, [
                        _vm._v(
                          "注：排序值用于资产剖面图中同层排序，数值越小排列越左"
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }