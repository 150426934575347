var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "validHouseForm-container" },
    [
      _vm.showMerchantsInfo
        ? _c(
            "div",
            { staticStyle: { height: "90%" } },
            [
              _vm._m(0),
              _c("tabs-panel", {
                ref: "tabs",
                attrs: {
                  tabs: _vm.tabs,
                  before: _vm.getHouseInfoData,
                  tabRouter: false,
                  activeLabel: _vm.activeLabel,
                },
                on: {
                  "update:activeLabel": function ($event) {
                    _vm.activeLabel = $event
                  },
                  "update:active-label": function ($event) {
                    _vm.activeLabel = $event
                  },
                },
              }),
            ],
            1
          )
        : _c(
            "form-panel",
            _vm._b(
              {
                ref: "formPanel",
                attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
                on: { update: _vm.update },
              },
              "form-panel",
              _vm.submitConfig,
              false
            ),
            [
              _c(
                "col2-detail",
                [
                  _c(
                    "col2-block",
                    { attrs: { title: "房号基础信息" } },
                    [
                      _vm.userType !== "101"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "所属项目",
                                prop: "communityId",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择所属项目",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "v-select2",
                                _vm._b(
                                  {
                                    attrs: {
                                      placeholder: "请选择所属项目",
                                      disabled:
                                        !_vm.isAdd || _vm.showMerchantsInfo,
                                      width: _vm.width,
                                    },
                                    on: { onChange: _vm.communityChange },
                                    model: {
                                      value: _vm.form.communityId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "communityId", $$v)
                                      },
                                      expression: "form.communityId",
                                    },
                                  },
                                  "v-select2",
                                  _vm.communityParams,
                                  false
                                )
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("el-form-item", { attrs: { label: "房号信息" } }, [
                        _c("div", { staticClass: "elform-content-wrapper" }, [
                          _c(
                            "div",
                            { staticClass: "elform-item-wrapper" },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "v-autocomplete",
                                    _vm._b(
                                      {
                                        attrs: {
                                          disabled: _vm.showMerchantsInfo,
                                          extra: _vm.extraBlockParams,
                                          placeholder: "苑",
                                          width: _vm.width,
                                        },
                                        model: {
                                          value: _vm.form.blockName,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "blockName", $$v)
                                          },
                                          expression: "form.blockName",
                                        },
                                      },
                                      "v-autocomplete",
                                      _vm.blockParams,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "elform-item-wrapper" },
                            [
                              _c("span", { staticClass: "mark-color" }, [
                                _vm._v("*"),
                              ]),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "buildingNumber",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入楼幢/停车场",
                                        trigger: "change",
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "v-autocomplete",
                                    _vm._b(
                                      {
                                        attrs: {
                                          disabled: _vm.showMerchantsInfo,
                                          extra: _vm.extraBuildingParams,
                                          placeholder: "楼幢/停车场",
                                          width: _vm.width,
                                        },
                                        model: {
                                          value: _vm.form.buildingNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "buildingNumber",
                                              $$v
                                            )
                                          },
                                          expression: "form.buildingNumber",
                                        },
                                      },
                                      "v-autocomplete",
                                      _vm.buildingParams,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "elform-item-wrapper" },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "v-autocomplete",
                                    _vm._b(
                                      {
                                        attrs: {
                                          disabled: _vm.showMerchantsInfo,
                                          extra: _vm.extraUnitParams,
                                          placeholder: "单元/车区",
                                          width: _vm.width,
                                        },
                                        model: {
                                          value: _vm.form.unit,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "unit", $$v)
                                          },
                                          expression: "form.unit",
                                        },
                                      },
                                      "v-autocomplete",
                                      _vm.unitParams,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "elform-item-wrapper" },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("v-input", {
                                    attrs: {
                                      disabled: _vm.showMerchantsInfo,
                                      placeholder: "室/车位/车库",
                                      width: _vm.width,
                                    },
                                    model: {
                                      value: _vm.form.room,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "room", $$v)
                                      },
                                      expression: "form.room",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属楼层",
                            prop: "floor",
                            rules: [
                              {
                                required: true,
                                message: "请输入所属楼层",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input-number", {
                            attrs: {
                              disabled: _vm.showMerchantsInfo,
                              min: -99,
                              max: 999,
                              placeholder: "请输入所属楼层",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.floor,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "floor", $$v)
                              },
                              expression: "form.floor",
                            },
                          }),
                          _c("span", { staticClass: "tip-text" }, [
                            _vm._v("层/楼"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "建筑类型",
                            prop: "buildType",
                            rules: [
                              {
                                required: true,
                                message: "请选择建筑类型",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              disabled: _vm.showMerchantsInfo,
                              options: _vm.buildTypeOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.buildType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "buildType", $$v)
                              },
                              expression: "form.buildType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "房屋类型",
                            prop: "houseType",
                            rules: [
                              {
                                required: true,
                                message: "请选择房屋类型",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              disabled: _vm.showMerchantsInfo,
                              options: _vm.houseTypeOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.houseType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "houseType", $$v)
                              },
                              expression: "form.houseType",
                            },
                          }),
                          _c("span", { staticClass: "tip-text" }, [
                            _vm._v(
                              "注：当房屋类型为公寓、住宅、排屋、别墅、商铺、办公用房、物业经营用房时，才能显示在App中，被用户关联"
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm.form.houseType == 24 || _vm.form.houseType == 25
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否为子母车位",
                                prop: "isParent",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.isServerCenterOps,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.isParent,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "isParent", $$v)
                                  },
                                  expression: "form.isParent",
                                },
                              }),
                              _c("span", { staticClass: "tip-text" }, [
                                _vm._v(
                                  "注：子母车位涉及与第三方车场车辆管理等核心业务，请慎重操作"
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否为服务中心",
                            prop: "isServerCenter",
                            rules: [
                              {
                                required: true,
                                message: "请选择",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              disabled: _vm.showMerchantsInfo,
                              options: _vm.isServerCenterOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.isServerCenter,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isServerCenter", $$v)
                              },
                              expression: "form.isServerCenter",
                            },
                          }),
                          _c("span", { staticClass: "tip-text" }, [
                            _vm._v(
                              "注：服务中心房号涉及工单报事等核心业务，请慎重操作"
                            ),
                          ]),
                        ],
                        1
                      ),
                      !_vm.isAdd
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否关联第三方户号",
                                prop: "isHaveThirdhouse",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "elform-content-wrapper" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "elform-item-wrapper" },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          options: _vm.isHaveThirdhouseOps,
                                          width: _vm.width,
                                        },
                                        model: {
                                          value: _vm.form.isHaveThirdhouse,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "isHaveThirdhouse",
                                              $$v
                                            )
                                          },
                                          expression: "form.isHaveThirdhouse",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.form.isHaveThirdhouse
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "elform-item-wrapper",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("第三方名称："),
                                            ]),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop: "ncName",
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message: "请选择",
                                                      trigger: "change",
                                                    },
                                                  ],
                                                },
                                              },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    disabled: true,
                                                    options: _vm.ncNameOps,
                                                    width: _vm.width,
                                                  },
                                                  model: {
                                                    value: _vm.form.ncName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "ncName",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.ncName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "elform-item-wrapper",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("第三方户号："),
                                            ]),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop: "ncHouse",
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message:
                                                        "请输入第三方户号",
                                                      trigger: "blur",
                                                    },
                                                  ],
                                                },
                                              },
                                              [
                                                _c("v-input", {
                                                  attrs: {
                                                    placeholder:
                                                      "请输入第三方户号",
                                                    maxlength: 50,
                                                    width: _vm.width,
                                                  },
                                                  model: {
                                                    value: _vm.form.ncHouse,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "ncHouse",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.ncHouse",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-button", {
                                          staticStyle: { height: "36px" },
                                          attrs: { text: "添加第三方车位号" },
                                          on: { click: _vm.addParkingNumber },
                                        }),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.form.isHaveThirdhouse
                        ? _c(
                            "div",
                            { staticClass: "parkingNum" },
                            _vm._l(_vm.form.ncGarages, function (item, index) {
                              return _c(
                                "el-form-item",
                                {
                                  key: index,
                                  attrs: {
                                    label: `第三方车位号${index + 1}`,
                                    prop: `ncGarages.${index}`,
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入第三方车位号",
                                        trigger: "blur",
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("v-input", {
                                    attrs: {
                                      placeholder: "请输入第三方车位号",
                                      maxlength: 50,
                                      width: 200,
                                    },
                                    model: {
                                      value: _vm.form.ncGarages[index],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form.ncGarages, index, $$v)
                                      },
                                      expression: "form.ncGarages[index]",
                                    },
                                  }),
                                  _c("v-button", {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { type: "danger", text: "删除" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delParkingNumber(index)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "col2-block",
                    { attrs: { title: "房号状态" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "房屋状态", required: "" } },
                        [
                          _c("div", { staticClass: "elform-content-wrapper" }, [
                            _c(
                              "div",
                              { staticClass: "elform-item-wrapper" },
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top-start",
                                      width: "240",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        "\n                  空置：房屋未售"
                                      ),
                                      _c("br"),
                                      _vm._v(" 未领：房屋已售，业主未入伙"),
                                      _c("br"),
                                      _vm._v(
                                        " 空关：房屋已售，业主已入伙未入住"
                                      ),
                                      _c("br"),
                                      _vm._v(" 装修：房屋正在装修"),
                                      _c("br"),
                                      _vm._v(
                                        " 入住：装修完成，业主已入住\n                "
                                      ),
                                    ]),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-question infoSupportType",
                                      attrs: { slot: "reference" },
                                      slot: "reference",
                                    }),
                                  ]
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "roomStatus",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择房屋状态",
                                          trigger: "change",
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        disabled: _vm.showMerchantsInfo,
                                        width: _vm.width,
                                        options: _vm.roomStatusOps,
                                      },
                                      model: {
                                        value: _vm.form.roomStatus,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "roomStatus", $$v)
                                        },
                                        expression: "form.roomStatus",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "elform-item-wrapper" },
                              [
                                _c("span", { staticClass: "mark-color" }, [
                                  _vm._v("*"),
                                ]),
                                _c("span", { staticClass: "mark-text" }, [
                                  _vm._v("是否可售："),
                                ]),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "canSell",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择是否可售",
                                          trigger: "change",
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        disabled: _vm.showMerchantsInfo,
                                        width: _vm.width,
                                        options: _vm.saleTypeOps,
                                      },
                                      model: {
                                        value: _vm.form.canSell,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "canSell", $$v)
                                        },
                                        expression: "form.canSell",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.form.canSell === 1
                              ? _c(
                                  "div",
                                  { staticClass: "elform-item-wrapper" },
                                  [
                                    _c("span", { staticClass: "mark-color" }, [
                                      _vm._v("*"),
                                    ]),
                                    _c("span", { staticClass: "mark-text" }, [
                                      _vm._v("出售状态："),
                                    ]),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: "isSell",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请选择出售状态",
                                              trigger: "change",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            disabled: _vm.showMerchantsInfo,
                                            width: _vm.width,
                                            options: _vm.saleStatusOps,
                                          },
                                          model: {
                                            value: _vm.form.isSell,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "isSell", $$v)
                                            },
                                            expression: "form.isSell",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "使用状态", required: "" } },
                        [
                          _c("div", { staticClass: "elform-content-wrapper" }, [
                            _c(
                              "div",
                              { staticClass: "elform-item-wrapper" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "usingStatus",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择使用状态",
                                          trigger: "change",
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        disabled: _vm.showMerchantsInfo,
                                        width: _vm.width,
                                        options: _vm.usingStatusOps,
                                      },
                                      model: {
                                        value: _vm.form.usingStatus,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "usingStatus", $$v)
                                        },
                                        expression: "form.usingStatus",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "elform-item-wrapper" },
                              [
                                _c("span", { staticClass: "mark-color" }, [
                                  _vm._v("*"),
                                ]),
                                _c("span", { staticClass: "mark-text" }, [
                                  _vm._v("是否可租："),
                                ]),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "canLease",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择是否可租",
                                          trigger: "change",
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        disabled: _vm.showMerchantsInfo,
                                        width: _vm.width,
                                        options: _vm.rentTypeOps,
                                      },
                                      model: {
                                        value: _vm.form.canLease,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "canLease", $$v)
                                        },
                                        expression: "form.canLease",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.form.canLease === 1
                              ? _c(
                                  "div",
                                  { staticClass: "elform-item-wrapper" },
                                  [
                                    _c("span", { staticClass: "mark-color" }, [
                                      _vm._v("*"),
                                    ]),
                                    _c("span", { staticClass: "mark-text" }, [
                                      _vm._v("出租状态："),
                                    ]),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: "isLease",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请选择出租状态",
                                              trigger: "change",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            disabled: _vm.showMerchantsInfo,
                                            width: _vm.width,
                                            options: _vm.rentStatusOps,
                                          },
                                          model: {
                                            value: _vm.form.isLease,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "isLease", $$v)
                                            },
                                            expression: "form.isLease",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.showMerchantsInfo
                    ? _c(
                        "col2-block",
                        { attrs: { title: "招商信息" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "图片" } },
                            [
                              _c("v-uploader", {
                                attrs: {
                                  action: _vm.uploadURL,
                                  imgUrls: _vm.form.housePicList,
                                  limit: 12,
                                  fileSize: 5,
                                },
                                on: {
                                  "update:imgUrls": function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "housePicList",
                                      $event
                                    )
                                  },
                                  "update:img-urls": function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "housePicList",
                                      $event
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "tip",
                                      fn: function () {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              "第一张图片将作为封面图，每张图片大小不超5M，最多可上传12张图片"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  4078428584
                                ),
                              }),
                            ],
                            1
                          ),
                          _c("el-form-item", { attrs: { label: "租金" } }, [
                            _c(
                              "div",
                              { staticClass: "elform-item-custom-wrapper" },
                              [
                                _c(
                                  "el-form-item",
                                  { staticClass: "elform-item-wrapper" },
                                  [
                                    _c("v-input-number", {
                                      attrs: {
                                        placeholder: "请填写租金金额",
                                        min: 0.01,
                                        max: 99999999.99,
                                        precision: 2,
                                        width: _vm.width,
                                      },
                                      model: {
                                        value: _vm.form.leasePrice,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "leasePrice", $$v)
                                        },
                                        expression: "form.leasePrice",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        options: _vm.leaseUnitOps,
                                        width: _vm.width,
                                        placeholder: "请选择单位",
                                      },
                                      model: {
                                        value: _vm.form.leaseUnit,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "leaseUnit", $$v)
                                        },
                                        expression: "form.leaseUnit",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { label: "招商简介" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  placeholder: "请输入简介内容",
                                  maxlength: 500,
                                },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "col2-block",
                    { attrs: { title: "其他信息" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "排序",
                            prop: "sort",
                            rules: [
                              {
                                required: true,
                                message: "请输入排序",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input-number", {
                            attrs: {
                              disabled: _vm.showMerchantsInfo,
                              min: 0,
                              max: 999,
                              placeholder: "请输入排序",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "sort", $$v)
                              },
                              expression: "form.sort",
                            },
                          }),
                          _c("span", { staticClass: "tip-text" }, [
                            _vm._v(
                              "注：排序值用于资产剖面图中同层排序，数值越小排列越左"
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "col2-item",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "计费面积",
                                prop: "chargingArea",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入计费面积",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "v-input-number",
                                _vm._b(
                                  {
                                    attrs: {
                                      disabled: _vm.showMerchantsInfo,
                                      placeholder: "请输入计费面积",
                                      width: _vm.width,
                                    },
                                    model: {
                                      value: _vm.form.chargingArea,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "chargingArea", $$v)
                                      },
                                      expression: "form.chargingArea",
                                    },
                                  },
                                  "v-input-number",
                                  _vm.areaNumParams,
                                  false
                                )
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "col2-item",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "建筑面积",
                                prop: "buildingArea",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入建筑面积",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "v-input-number",
                                _vm._b(
                                  {
                                    attrs: {
                                      disabled: _vm.showMerchantsInfo,
                                      placeholder: "请输入建筑面积",
                                      width: _vm.width,
                                    },
                                    model: {
                                      value: _vm.form.buildingArea,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "buildingArea", $$v)
                                      },
                                      expression: "form.buildingArea",
                                    },
                                  },
                                  "v-input-number",
                                  _vm.areaNumParams,
                                  false
                                )
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "col2-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isOpen,
                              expression: "isOpen",
                            },
                          ],
                          attrs: { span: 24 },
                        },
                        [
                          _c(
                            "col2-item",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "房屋户型" } },
                                [
                                  _c("v-input", {
                                    attrs: {
                                      disabled: _vm.showMerchantsInfo,
                                      placeholder: "请输入房屋户型",
                                      width: _vm.width,
                                      maxlength: 30,
                                    },
                                    model: {
                                      value: _vm.form.roomStyle,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "roomStyle", $$v)
                                      },
                                      expression: "form.roomStyle",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "col2-item",
                            { attrs: { span: 8 } },
                            [
                              !_vm.isAdd
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "欠费金额" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.form.propertyFeeArrearage)
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "col2-item",
                            { attrs: { span: 8 } },
                            [
                              !_vm.isAdd
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "缴纳日期" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.propertyFeePaidToDate)
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "col2-item",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "套内面积" } },
                                [
                                  _c(
                                    "v-input-number",
                                    _vm._b(
                                      {
                                        attrs: {
                                          disabled: _vm.showMerchantsInfo,
                                          placeholder: "请输入套内面积",
                                          width: _vm.width,
                                        },
                                        model: {
                                          value: _vm.form.innerArea,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "innerArea", $$v)
                                          },
                                          expression: "form.innerArea",
                                        },
                                      },
                                      "v-input-number",
                                      _vm.areaNumParams,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "col2-item",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "分摊面积" } },
                                [
                                  _c(
                                    "v-input-number",
                                    _vm._b(
                                      {
                                        attrs: {
                                          disabled: _vm.showMerchantsInfo,
                                          placeholder: "请输入分摊面积",
                                          width: _vm.width,
                                        },
                                        model: {
                                          value: _vm.form.publicArea,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "publicArea",
                                              $$v
                                            )
                                          },
                                          expression: "form.publicArea",
                                        },
                                      },
                                      "v-input-number",
                                      _vm.areaNumParams,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "col2-item",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "辅助面积" } },
                                [
                                  _c(
                                    "v-input-number",
                                    _vm._b(
                                      {
                                        attrs: {
                                          disabled: _vm.showMerchantsInfo,
                                          placeholder: "请输入辅助面积",
                                          width: _vm.width,
                                        },
                                        model: {
                                          value: _vm.form.assistChargingArea,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "assistChargingArea",
                                              $$v
                                            )
                                          },
                                          expression: "form.assistChargingArea",
                                        },
                                      },
                                      "v-input-number",
                                      _vm.areaNumParams,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "col2-item",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "花园面积" } },
                                [
                                  _c(
                                    "v-input-number",
                                    _vm._b(
                                      {
                                        attrs: {
                                          disabled: _vm.showMerchantsInfo,
                                          placeholder: "请输入花园面积",
                                          width: _vm.width,
                                        },
                                        model: {
                                          value: _vm.form.gardenArea,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "gardenArea",
                                              $$v
                                            )
                                          },
                                          expression: "form.gardenArea",
                                        },
                                      },
                                      "v-input-number",
                                      _vm.areaNumParams,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "col2-item",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "地下室面积" } },
                                [
                                  _c(
                                    "v-input-number",
                                    _vm._b(
                                      {
                                        attrs: {
                                          disabled: _vm.showMerchantsInfo,
                                          placeholder: "请输入地下室面积",
                                          width: _vm.width,
                                        },
                                        model: {
                                          value: _vm.form.undergroundArea,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "undergroundArea",
                                              $$v
                                            )
                                          },
                                          expression: "form.undergroundArea",
                                        },
                                      },
                                      "v-input-number",
                                      _vm.areaNumParams,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "col2-item",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "统计面积" } },
                                [
                                  _c(
                                    "v-input-number",
                                    _vm._b(
                                      {
                                        attrs: {
                                          disabled: _vm.showMerchantsInfo,
                                          placeholder: "请输入统计面积",
                                          width: _vm.width,
                                        },
                                        model: {
                                          value: _vm.form.statisticalArea,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "statisticalArea",
                                              $$v
                                            )
                                          },
                                          expression: "form.statisticalArea",
                                        },
                                      },
                                      "v-input-number",
                                      _vm.areaNumParams,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "col2-item",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "是否用于测试或展示" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: { disabled: _vm.showMerchantsInfo },
                                    on: { change: _vm.isVirtualChange },
                                    model: {
                                      value: _vm.form.isVirtual,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "isVirtual", $$v)
                                      },
                                      expression: "form.isVirtual",
                                    },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      "用于测试或展示的房号不计入统计数据"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "col2-item",
                        { staticClass: "click-more", attrs: { span: 24 } },
                        [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isOpen,
                                  expression: "!isOpen",
                                },
                              ],
                              staticClass: "btn",
                              on: {
                                click: function ($event) {
                                  _vm.isOpen = true
                                },
                              },
                            },
                            [
                              _vm._v("展开选填信息 "),
                              _c("i", { staticClass: "el-icon-arrow-down" }),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isOpen,
                                  expression: "isOpen",
                                },
                              ],
                              staticClass: "btn",
                              on: {
                                click: function ($event) {
                                  _vm.isOpen = false
                                },
                              },
                            },
                            [
                              _vm._v("收起 "),
                              _c("i", { staticClass: "el-icon-arrow-up" }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top-tip-wrapper" }, [
      _c("div", { staticClass: "red-text" }, [_vm._v("温馨提示：")]),
      _c("div", { staticClass: "tip-text" }, [
        _vm._v(
          "此功能仅可编辑房间的招商信息，其他信息请在【项目房号配置】中进行配置"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }