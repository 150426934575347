<template>
  <div class="merchantInfo">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
    >
      <el-form-item label="图片">
        <v-uploader :action="uploadURL" :imgUrls.sync="form.housePicList" :limit="12" :fileSize="5">
          <template #tip>
            <span>第一张图片将作为封面图，每张图片大小不超5M，最多可上传12张图片</span>
          </template>
        </v-uploader>
      </el-form-item>
      <el-form-item  label="租金">
        <div class="elform-item-custom-wrapper">
          <el-form-item class="elform-item-wrapper" >
            <v-input-number
                v-model="form.leasePrice"
                placeholder="请填写租金金额"
                :min="0.01"
                :max="99999999.99"
                :precision="2"
                :width="width"
              ></v-input-number>
          </el-form-item>
          <el-form-item >
            <v-select v-model="form.leaseUnit" :options="leaseUnitOps" :width="width" placeholder="请选择单位"></v-select>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item
        label="建筑面积"
        prop="buildingArea"
        :rules="[{ required: true, message: '请输入建筑面积', trigger: 'blur'}]"
      >
        <v-input-number :disabled="true" v-model="form.buildingArea" v-bind="areaNumParams" placeholder="请输入建筑面积" :width="width"></v-input-number>
        <field-tips tipsText='注：与项目房号配置同步'></field-tips>
      </el-form-item>
      <el-form-item label="招商简介" >
          <v-textarea
            v-model="form.remark"
            placeholder="请输入简介内容"
            :maxlength="500"
          ></v-textarea>
      </el-form-item>
      <template v-if="showMerchantsInfo" #footerSlot>
        <v-button text="保存" type="success" :disabled="submitDisabled" @click="saveClick"></v-button>
      </template>
    </form-panel>
  </div>
</template>

<script>
import {
  uploadImageURL,
  getCommunityRoomNumLeaseUnitURL,
  updateMerchantsInfoURL
} from '../api'
import { vUploader } from 'components/control'

export default {
  components: {
    vUploader
  },
  props: {
    responseData: Object
  },
  data () {
    return {
      width: 180,
      uploadURL: uploadImageURL,
      leaseUnitOps: [],
      communityName: '',
      propertyFeePaidToDate: '',
      submitConfig: {
        submitUrl: '',
        submitMethod: 'put',
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      form: {},
      areaNumParams: {
        precision: 2,
        max: 9999.99,
        min: 0
      },
      showMerchantsInfo: false,
      isAdd: false,
      submitDisabled: false
    }
  },

  created () {
    //
    const { merchants, id } = this.$route.query
    this.isAdd = !id
    if (merchants !== undefined) {
      this.showMerchantsInfo = true
    }
    this.form = this.responseData.form
  },
  mounted () {
    this.getCommunityRoomNumLeaseUnit()
  },
  methods: {
    // 获取房号的租金单位类型
    getCommunityRoomNumLeaseUnit () {
      this.$axios.get(getCommunityRoomNumLeaseUnitURL).then(res => {
        if (res.status === 100) {
          let { data } = res
          let ops = data.map((val) => {
            return {
              text: val.name,
              value: Number(val.code)
            }
          }) || []
          let tempOps = [
            {
              text: '请选择单位',
              value: undefined
            }
          ]
          this.leaseUnitOps = tempOps.concat(ops)
          // if (!this.form.leaseUnit) {
          //   let defaultVal = ops[0] ? ops[0].value : undefined
          //   this.$set(this.form, 'leaseUnit', defaultVal)
          // }
        }
      })
    },
    setSubmitDisabled (status) {
      this.submitDisabled = status
    },
    // 保存招商信息 TODO url需要确认
    saveClick () {
      if (this.form.leasePrice && (!this.form.leaseUnit)) {
        this.$message('请选择单位')
        return false
      }

      if (this.form.leaseUnit && (!this.form.leasePrice)) {
        this.$message('请填写租金金额')
        return false
      }

      if (!this.form.buildingArea) {
        this.$message('请填写建筑面积')
        return false
      }

      let params = {
        id: this.form.busiPolicyId,
        houseId: this.form.id,
        // communityBusiId: this.form.communityId,
        housePicList: this.form.housePicList || [],
        // 租金
        leasePrice: this.form.leasePrice || 0,
        // 租金单位
        leaseUnit: this.form.leaseUnit || 0,
        // 招商简介
        remark: this.form.remark || '',
        // 建筑面积
        buildingArea: this.form.buildingArea || 0
      }
      this.setSubmitDisabled(true)
      let _this = this
      this.$axios({
        method: 'PUT',
        url: updateMerchantsInfoURL,
        data: params
      }).then(res => {
        _this.setSubmitDisabled(false)
        if (res.status === 100) {
          _this.$message.success('保存成功')
          _this.$router.go(-1)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.merchantInfo {
  .top-tip-wrapper {
    width: 90%;
    background: #fffbe8;
    display: flex;
    flex-direction: column;

    .red-text {
      margin: 10px;
      color: red;
      font-size: 12px;
    }

    .tip-text {
      margin-left: 10px;
      margin-bottom: 10px;
      font-size: 12px;
    }
  }
  .elform-content-wrapper {
    display: flex;
    justify-content: flex-start;

    .mark-color {
      color: red;
      margin-right: 5px;
    }

    .mark-text {
      margin-right: 5px;
      font-weight: bold;
      color: #333;
      width: 70px;
      flex-shrink: 0;
    }
  }

  .elform-item-wrapper {
      display: flex;
      margin-right: 20px;
    }

  .elform-item-custom-wrapper {
    display: flex;
    flex-direction: row;
  }

  .tip-text {
    margin-left: 10px;
  }
}
</style>
