<template>
  <div class="validHouseForm-container">
    <div v-if="showMerchantsInfo" style="height: 90%">
      <div class="top-tip-wrapper">
        <div class="red-text">温馨提示：</div>
        <div class="tip-text">此功能仅可编辑房间的招商信息，其他信息请在【项目房号配置】中进行配置</div>
      </div>
      <tabs-panel ref="tabs" :tabs="tabs" :before="getHouseInfoData" :tabRouter="false"
        :activeLabel.sync="activeLabel"></tabs-panel>
    </div>

    <form-panel ref="formPanel" v-bind="submitConfig" v-else :form="form" :submitBefore="submitBefore" @update="update">
      <col2-detail>
        <col2-block title="房号基础信息">
          <el-form-item label="所属项目" prop="communityId" v-if="userType !== '101'"
            :rules="[{ required: true, message: '请选择所属项目', trigger: 'change' }]">
            <v-select2 v-model="form.communityId" v-bind="communityParams" placeholder="请选择所属项目"
              :disabled="!isAdd || showMerchantsInfo" @onChange="communityChange" :width="width"></v-select2>
          </el-form-item>
          <el-form-item label="房号信息">
            <div class="elform-content-wrapper">
              <div class="elform-item-wrapper">
                <el-form-item>
                  <v-autocomplete :disabled="showMerchantsInfo" v-bind="blockParams" :extra="extraBlockParams"
                    v-model="form.blockName" placeholder="苑" :width="width"></v-autocomplete>
                </el-form-item>
              </div>
              <div class="elform-item-wrapper">
                <span class="mark-color">*</span>
                <el-form-item prop="buildingNumber" :rules="[{ required: true, message: '请输入楼幢/停车场', trigger: 'change' }]">
                  <v-autocomplete :disabled="showMerchantsInfo" v-bind="buildingParams" :extra="extraBuildingParams"
                    v-model="form.buildingNumber" placeholder="楼幢/停车场" :width="width"></v-autocomplete>
                  <!-- <v-input :disabled="showMerchantsInfo" v-model="form.buildingNumber" placeholder="楼幢/停车场" :width="width"></v-input> -->
                </el-form-item>
              </div>
              <div class="elform-item-wrapper">
                <el-form-item>
                  <v-autocomplete :disabled="showMerchantsInfo" v-bind="unitParams" :extra="extraUnitParams"
                    v-model="form.unit" placeholder="单元/车区" :width="width"></v-autocomplete>
                  <!-- <v-input :disabled="showMerchantsInfo" v-model="form.unit" placeholder="单元/车区" :width="width"></v-input> -->
                </el-form-item>
              </div>
              <div class="elform-item-wrapper">
                <el-form-item>
                  <v-input :disabled="showMerchantsInfo" v-model="form.room" placeholder="室/车位/车库"
                    :width="width"></v-input>
                </el-form-item>
              </div>
            </div>
          </el-form-item>
          <!--<el-form-item
            label="租户配置项"
          >
            <v-input :disabled="showMerchantsInfo" v-model="form.newwinHouseNum" :width="300" :maxlength="60"></v-input>
          </el-form-item>-->
          <el-form-item label="所属楼层" prop="floor" :rules="[{ required: true, message: '请输入所属楼层', trigger: 'blur' }]">
            <v-input-number :disabled="showMerchantsInfo" v-model="form.floor" :min="-99" :max="999" placeholder="请输入所属楼层"
              :width="width"></v-input-number>
            <span class="tip-text">层/楼</span>
          </el-form-item>
          <!-- <el-form-item
            label="是否用于测试或展示"
            prop="isVirtual"
            :rules="[{ required: true, message: '请选择是否用于测试或展示', trigger: 'change'}]"
          >
            <v-select :disabled="showMerchantsInfo" v-model="form.isVirtual" :options="isVirtualOps" :width="width"></v-select>
            <span class="tip-text">用于测试或展示的房号不计入统计数据</span>
          </el-form-item> -->
          <el-form-item label="建筑类型" prop="buildType" :rules="[{ required: true, message: '请选择建筑类型', trigger: 'change' }]">
            <v-select :disabled="showMerchantsInfo" v-model="form.buildType" :options="buildTypeOps"
              :width="width"></v-select>
          </el-form-item>
          <el-form-item label="房屋类型" prop="houseType" :rules="[{ required: true, message: '请选择房屋类型', trigger: 'change' }]">
            <v-select :disabled="showMerchantsInfo" v-model="form.houseType" :options="houseTypeOps"
              :width="width"></v-select>
            <span class="tip-text">注：当房屋类型为公寓、住宅、排屋、别墅、商铺、办公用房、物业经营用房时，才能显示在App中，被用户关联</span>
          </el-form-item>
          <el-form-item v-if="form.houseType == 24 || form.houseType == 25" label="是否为子母车位" prop="isParent"
            :rules="[{ required: true, message: '请选择', trigger: 'change' }]">
            <v-select v-model="form.isParent" :options="isServerCenterOps" :width="width"></v-select>
            <span class="tip-text">注：子母车位涉及与第三方车场车辆管理等核心业务，请慎重操作</span>
          </el-form-item>

          <el-form-item label="是否为服务中心" prop="isServerCenter"
            :rules="[{ required: true, message: '请选择', trigger: 'change' }]">
            <v-select :disabled="showMerchantsInfo" v-model="form.isServerCenter" :options="isServerCenterOps"
              :width="width"></v-select>
            <span class="tip-text">注：服务中心房号涉及工单报事等核心业务，请慎重操作</span>
          </el-form-item>

          <el-form-item label="是否关联第三方户号" prop="isHaveThirdhouse"
            :rules="[{ required: true, message: '请选择', trigger: 'change' }]" v-if="!isAdd">
            <div class="elform-content-wrapper">
              <div class="elform-item-wrapper">
                <v-select v-model="form.isHaveThirdhouse" :options="isHaveThirdhouseOps" :width="width"></v-select>
              </div>
              <template v-if="form.isHaveThirdhouse">
                <div class="elform-item-wrapper">
                  <span>第三方名称：</span>
                  <el-form-item prop="ncName" :rules="[{ required: true, message: '请选择', trigger: 'change' }]">
                    <v-select :disabled="true" v-model="form.ncName" :options="ncNameOps" :width="width"></v-select>
                  </el-form-item>
                </div>
                <div class="elform-item-wrapper">
                  <span>第三方户号：</span>
                  <el-form-item prop="ncHouse" :rules="[{ required: true, message: '请输入第三方户号', trigger: 'blur' }]">
                    <v-input v-model="form.ncHouse" placeholder="请输入第三方户号" :maxlength="50" :width="width"></v-input>
                  </el-form-item>
                </div>
                <v-button style="height:36px" text="添加第三方车位号" @click="addParkingNumber"></v-button>
              </template>
            </div>

          </el-form-item>

          <div class="parkingNum" v-if="form.isHaveThirdhouse">
            <el-form-item v-for="(item, index) in form.ncGarages" :key="index" :label="`第三方车位号${index + 1}`"
              :prop="`ncGarages.${index}`" :rules="[{ required: true, message: '请输入第三方车位号', trigger: 'blur' }]">
              <v-input v-model="form.ncGarages[index]" placeholder="请输入第三方车位号" :maxlength="50" :width="200"></v-input>
              <v-button style="margin-left:10px" type="danger" text="删除" @click="delParkingNumber(index)"></v-button>
            </el-form-item>
          </div>
        </col2-block>
        <col2-block title="房号状态">
          <el-form-item label="房屋状态" required>
            <div class="elform-content-wrapper">
              <div class="elform-item-wrapper">
                <el-popover placement="top-start" width="240" trigger="hover">
                  <div>
                    空置：房屋未售</br> 未领：房屋已售，业主未入伙</br> 空关：房屋已售，业主已入伙未入住</br> 装修：房屋正在装修</br> 入住：装修完成，业主已入住
                  </div>
                  <i slot="reference" class="el-icon-question infoSupportType"> </i>
                </el-popover>
                <el-form-item prop="roomStatus" :rules="[{ required: true, message: '请选择房屋状态', trigger: 'change' }]">
                  <v-select :disabled="showMerchantsInfo" v-model="form.roomStatus" :width="width"
                    :options="roomStatusOps"></v-select>
                </el-form-item>
              </div>
              <div class="elform-item-wrapper">
                <span class="mark-color">*</span>
                <span class="mark-text">是否可售：</span>
                <el-form-item prop="canSell" :rules="[{ required: true, message: '请选择是否可售', trigger: 'change' }]">
                  <v-select :disabled="showMerchantsInfo" v-model="form.canSell" :width="width"
                    :options="saleTypeOps"></v-select>
                </el-form-item>
              </div>
              <div v-if="form.canSell === 1" class="elform-item-wrapper">
                <span class="mark-color">*</span>
                <span class="mark-text">出售状态：</span>
                <el-form-item prop="isSell" :rules="[{ required: true, message: '请选择出售状态', trigger: 'change' }]">
                  <v-select :disabled="showMerchantsInfo" v-model="form.isSell" :width="width"
                    :options="saleStatusOps"></v-select>
                </el-form-item>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="使用状态" required>
            <div class="elform-content-wrapper">
              <div class="elform-item-wrapper">
                <el-form-item prop="usingStatus" :rules="[{ required: true, message: '请选择使用状态', trigger: 'change' }]">
                  <v-select :disabled="showMerchantsInfo" v-model="form.usingStatus" :width="width"
                    :options="usingStatusOps"></v-select>
                </el-form-item>
              </div>
              <div class="elform-item-wrapper">
                <span class="mark-color">*</span>
                <span class="mark-text">是否可租：</span>
                <el-form-item prop="canLease" :rules="[{ required: true, message: '请选择是否可租', trigger: 'change' }]">
                  <v-select :disabled="showMerchantsInfo" v-model="form.canLease" :width="width"
                    :options="rentTypeOps"></v-select>
                </el-form-item>
              </div>
              <div v-if="form.canLease === 1" class="elform-item-wrapper">
                <span class="mark-color">*</span>
                <span class="mark-text">出租状态：</span>
                <el-form-item prop="isLease" :rules="[{ required: true, message: '请选择出租状态', trigger: 'change' }]">
                  <v-select :disabled="showMerchantsInfo" v-model="form.isLease" :width="width"
                    :options="rentStatusOps"></v-select>
                </el-form-item>
              </div>
            </div>
          </el-form-item>
        </col2-block>
        <!-- 招商信息新增 -->
        <col2-block v-if="showMerchantsInfo" title="招商信息">
          <el-form-item label="图片">
            <v-uploader :action="uploadURL" :imgUrls.sync="form.housePicList" :limit="12" :fileSize="5">
              <template #tip>
                <span>第一张图片将作为封面图，每张图片大小不超5M，最多可上传12张图片</span>
              </template>
            </v-uploader>
          </el-form-item>
          <el-form-item label="租金">
            <div class="elform-item-custom-wrapper">
              <el-form-item class="elform-item-wrapper">
                <v-input-number v-model="form.leasePrice" placeholder="请填写租金金额" :min="0.01" :max="99999999.99"
                  :precision="2" :width="width"></v-input-number>
              </el-form-item>
              <el-form-item>
                <v-select v-model="form.leaseUnit" :options="leaseUnitOps" :width="width" placeholder="请选择单位"></v-select>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item label="招商简介">
            <v-textarea v-model="form.remark" placeholder="请输入简介内容" :maxlength="500"></v-textarea>
          </el-form-item>
        </col2-block>
        <col2-block title="其他信息">
          <el-form-item label="排序" prop="sort" :rules="[{ required: true, message: '请输入排序', trigger: 'blur' }]">
            <v-input-number :disabled="showMerchantsInfo" v-model="form.sort" :min="0" :max="999" placeholder="请输入排序"
              :width="width"></v-input-number>
            <span class="tip-text">注：排序值用于资产剖面图中同层排序，数值越小排列越左</span>
          </el-form-item>
          <col2-item :span="8">
            <el-form-item label="计费面积" prop="chargingArea"
              :rules="[{ required: true, message: '请输入计费面积', trigger: 'blur' }]">
              <v-input-number :disabled="showMerchantsInfo" v-model="form.chargingArea" v-bind="areaNumParams"
                placeholder="请输入计费面积" :width="width"></v-input-number>
            </el-form-item>
          </col2-item>
          <col2-item :span="8">
            <el-form-item label="建筑面积" prop="buildingArea"
              :rules="[{ required: true, message: '请输入建筑面积', trigger: 'blur' }]">
              <v-input-number :disabled="showMerchantsInfo" v-model="form.buildingArea" v-bind="areaNumParams"
                placeholder="请输入建筑面积" :width="width"></v-input-number>
            </el-form-item>
          </col2-item>
          <col2-item :span="24" v-show="isOpen">
            <col2-item :span="8">
              <el-form-item label="房屋户型">
                <v-input :disabled="showMerchantsInfo" v-model="form.roomStyle" placeholder="请输入房屋户型" :width="width"
                  :maxlength="30"></v-input>
              </el-form-item>
            </col2-item>
            <col2-item :span="8">
              <el-form-item v-if="!isAdd" label="欠费金额">
                <span>{{ form.propertyFeeArrearage }}</span>
              </el-form-item>
            </col2-item>
            <col2-item :span="8">
              <el-form-item v-if="!isAdd" label="缴纳日期">
                <span>{{ propertyFeePaidToDate }}</span>
              </el-form-item>
            </col2-item>
            <col2-item :span="8">
              <el-form-item label="套内面积">
                <v-input-number :disabled="showMerchantsInfo" v-model="form.innerArea" v-bind="areaNumParams"
                  placeholder="请输入套内面积" :width="width"></v-input-number>
              </el-form-item>
            </col2-item>
            <col2-item :span="8">
              <el-form-item label="分摊面积">
                <v-input-number :disabled="showMerchantsInfo" v-model="form.publicArea" v-bind="areaNumParams"
                  placeholder="请输入分摊面积" :width="width"></v-input-number>
              </el-form-item>
            </col2-item>
            <col2-item :span="8">
              <el-form-item label="辅助面积">
                <v-input-number :disabled="showMerchantsInfo" v-model="form.assistChargingArea" v-bind="areaNumParams"
                  placeholder="请输入辅助面积" :width="width"></v-input-number>
              </el-form-item>
            </col2-item>
            <col2-item :span="8">
              <el-form-item label="花园面积">
                <v-input-number :disabled="showMerchantsInfo" v-model="form.gardenArea" v-bind="areaNumParams"
                  placeholder="请输入花园面积" :width="width"></v-input-number>
              </el-form-item>
            </col2-item>
            <col2-item :span="8">
              <el-form-item label="地下室面积">
                <v-input-number :disabled="showMerchantsInfo" v-model="form.undergroundArea" v-bind="areaNumParams"
                  placeholder="请输入地下室面积" :width="width"></v-input-number>
              </el-form-item>
            </col2-item>
            <col2-item :span="8">
              <el-form-item label="统计面积">
                <v-input-number :disabled="showMerchantsInfo" v-model="form.statisticalArea" v-bind="areaNumParams"
                  placeholder="请输入统计面积" :width="width"></v-input-number>
              </el-form-item>
            </col2-item>
            <col2-item :span="16">
              <el-form-item label="是否用于测试或展示">
                <v-checkbox v-model="form.isVirtual" :disabled="showMerchantsInfo"
                  @change="isVirtualChange"></v-checkbox><span>用于测试或展示的房号不计入统计数据</span>
              </el-form-item>
            </col2-item>
          </col2-item>
          <col2-item :span="24" class="click-more">
            <span class="btn" v-show="!isOpen" @click="isOpen = true">展开选填信息 <i class="el-icon-arrow-down"></i></span>
            <span class="btn" v-show="isOpen" @click="isOpen = false">收起 <i class="el-icon-arrow-up"></i></span>
          </col2-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import {
  getFullRoomInfoURL,
  saveRoomInfoURL,
  updateRoomInfoURL,
  // getCommunityRommTypesURL,
  // queryBlockNameFromCommunityURL,
  uploadImageURL,
  getCommunityRoomNumLeaseUnitURL,
  getMerchantsRoomURL,
  updateMerchantsInfoURL
} from './api'
import {
  roomStatusOps,
  verifyStatusOps,
  isVirtualOps,
  usingStatusOps,
  buildTypeOps,
  // new add
  saleTypeOps,
  saleStatusOps,
  rentTypeOps,
  rentStatusOps,
  isServerCenterOps,
  isHaveThirdhouseOps
} from './map'
import { vUploader, vCheckbox, vAutocomplete } from 'components/control'
import { Col2Detail, Col2Block, Col2Item, TabsPanel } from 'components/bussiness'
import { normalCommunityParams } from 'common/select2Params'
import merchantInfo from './merchantHouseTemplate/MerchantInfo'
import baseHouseInfo from './merchantHouseTemplate/BaseHouseInfo'
import {
  select2BlockURL,
  select2BuildingURL,
  select2UnitURL
} from 'common/api'
import { getHouseTypeData } from 'common/networkData'
export default {
  components: {
    Col2Detail,
    Col2Block,
    Col2Item,
    vAutocomplete,
    vUploader,
    TabsPanel,
    vCheckbox
  },
  data () {
    return {
      isValidHouseList: undefined,
      userType: this.$store.state.userInfo.userType,
      tabs: [],
      activeLabel: '招商信息',
      width: 180,
      uploadURL: uploadImageURL,
      saleTypeOps: saleTypeOps(),
      saleStatusOps: saleStatusOps(),
      rentTypeOps: rentTypeOps(),
      rentStatusOps: rentStatusOps(),
      roomStatusOps: roomStatusOps(2),
      leaseUnitOps: [],
      statusOps: verifyStatusOps,
      isVirtualOps,
      usingStatusOps: usingStatusOps(2),
      isServerCenterOps: isServerCenterOps(2),
      isHaveThirdhouseOps: isHaveThirdhouseOps(2),
      buildTypeOps,
      ncNameOps: [{ text: '用友', value: '用友' }],
      communityName: '',
      propertyFeePaidToDate: '',
      houseTypeOps: [],
      blockNameList: [],
      submitConfig: {
        submitUrl: '',
        submitMethod: 'put',
        queryUrl: getFullRoomInfoURL,
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
        submitText: '提交审核'
      },
      form: {
        id: '',
        communityId: '',
        isVirtual: false,
        houseType: undefined,
        roomStatus: 1,
        usingStatus: 1,
        buildType: 1,
        blockName: '',
        buildingNumber: '',
        unit: '',
        room: '',
        newwinHouseNum: '',
        floor: undefined,
        buildingArea: undefined,
        chargingArea: undefined,
        assistChargingArea: undefined,
        gardenArea: undefined,
        innerArea: undefined,
        publicArea: undefined,
        undergroundArea: undefined,
        statisticalArea: undefined,
        source: undefined,
        roomId: '',
        inuser: '',
        propertyFeeArrearage: '',
        roomStyle: '',
        carportType: undefined,
        // new add
        sort: undefined,
        canSell: 1,
        isSell: 0,
        canLease: 1,
        isLease: 0,
        // 招商信息需要填写的内容
        // 房号图片列表
        housePicList: [],
        // 租金
        leasePrice: undefined,
        // 租金单位
        leaseUnit: undefined,
        remark: '',
        // 招商政策id
        busiPolicyId: undefined,
        isServerCenter: 0,
        isParent: 0,
        // 是否关联第三方户号
        isHaveThirdhouse: 0,
        // 第三方名称
        ncName: '用友',
        // 第三方户号
        ncHouse: '',
        ncGarages: []
      },
      isVirtual: false,
      communityParams: normalCommunityParams,
      areaNumParams: {
        precision: 2,
        max: 9999.99,
        min: 0
      },
      isAdd: false,
      // 招商
      showMerchantsInfo: false,
      isOpen: false,
      response: {
        form: null
      },
      blockParams: {
        searchUrl: select2BlockURL,
        valueKey: 'name'
      },
      buildingParams: {
        searchUrl: select2BuildingURL,
        valueKey: 'name'
      },
      unitParams: {
        searchUrl: select2UnitURL,
        valueKey: 'name'
      }
    }
  },

  computed: {
    extraBlockParams () {
      return {
        communityId: this.form.communityId || ''
      }
    },
    extraBuildingParams () {
      return {
        communityId: this.form.communityId || '',
        blockName: this.form.blockName
      }
    },
    extraUnitParams () {
      return {
        communityId: this.form.communityId || '',
        blockName: this.form.blockName,
        buildingNumber: this.form.buildingNumber
      }
    }
  },

  created () {
    //
    const { merchants, id, isValidHouseList } = this.$route.query
    this.isAdd = !id
    this.isValidHouseList = isValidHouseList
    if (merchants !== undefined) {
      this.showMerchantsInfo = true
    }
    let title = this.isAdd ? '新增' : '编辑'
    this.tabs = [
      {
        label: '招商信息',
        component: merchantInfo,
        props: {
          responseData: this.response
        }
      }, {
        label: '房号基础信息',
        component: baseHouseInfo,
        props: {
          responseData: this.response
        }
      }
    ]
    this.$setBreadcrumb(title)
  },
  mounted () {
    if (!this.showMerchantsInfo) {
      this.getCommunityRommTypes()
      this.getCommunityRoomNumLeaseUnit()
      this.submitConfig.submitUrl = this.isAdd ? saveRoomInfoURL : updateRoomInfoURL
    }
    const { merchants, id } = this.$route.query
    if (!this.isAdd) {
      if (merchants !== undefined) {
        this.submitConfig.queryUrl = getMerchantsRoomURL
        // this.$refs.formPanel.getData({ houseId: id })
      } else {
        this.$refs.formPanel.getData({ id })
      }
    }
    if (this.userType === '101') {
      this.form.communityId = this.$store.state.userInfo.orgId
    }
  },
  methods: {
    setActiveLabel (activeLabel) {
      this.activeLabel = activeLabel
    },
    getHouseInfoData (next) {
      const { merchants, id } = this.$route.query
      let url = ''
      let params = {}
      if (!this.isAdd) {
        if (merchants !== undefined) {
          url = getMerchantsRoomURL
          params = { houseId: id }
        } else {
          url = getFullRoomInfoURL
          params = { id: id }
        }
      }
      if (this.isAdd) {
        next()
      } else {
        this.$axios.get(url, { params }).then(res => {
          if (res.status === 100) {
            let { data } = res
            this.update(data)
            next()
          }
        })
      }
    },
    update (data) {
      let keyArr = Object.keys(this.form)
      let newObj = {}
      data.buildType = data.buildType ? parseInt(data.buildType) : undefined
      data.housePicList = data.housePicList || []
      data.houseType = String(data.houseType)
      if (data && data.busiPolicy) {
        data.leasePrice = data.busiPolicy.leasePrice || undefined
        data.leaseUnit = data.busiPolicy.leaseUnit || undefined
        data.remark = data.busiPolicy.remark || ''
        data.busiPolicyId = data.busiPolicy.id
      }
      keyArr.forEach(value => {
        newObj[value] = data[value]
      })
      !newObj.buildType && (newObj.buildType = undefined)
      !newObj.gardenArea && (newObj.gardenArea = undefined)
      !newObj.undergroundArea && (newObj.undergroundArea = undefined)
      if (this.showMerchantsInfo) {
        this.response.form = newObj
      } else {
        this.form = newObj
        this.form.ncName = "用友"
      }
      this.communityName = data.communityName
      this.propertyFeePaidToDate = data.propertyFeePaidToDate
      if (!this.showMerchantsInfo) {
        this.getBlockNameList(data.communityId)
      }
      this.form.isVirtual = data.isVirtual ? true : false
    },
    submitBefore (data) {
      // 校验第三方车位号是否重复
      let newNcGarages = [...new Set(data.ncGarages)]
      if (data.isHaveThirdhouse && data.ncGarages && newNcGarages.length < data.ncGarages.length) {
        this.$message.warning('存在重复的第三方车位号')
        return false
      }
      if (data.houseType != 24 && data.houseType != 25) {
        data.isParent = 0
      }
      if (data.canSell === 0) {
        data.isSell = 0
      }
      if (data.canLease === 0) {
        data.isLease = 0
      }
      data.gardenArea = data.gardenArea || 0
      data.undergroundArea = data.undergroundArea || 0
      data.innerArea = data.innerArea || 0
      data.publicArea = data.publicArea || 0
      data.assistChargingArea = data.assistChargingArea || 0
      data.statisticalArea = data.statisticalArea || 0

      delete data.housePicList
      delete data.leasePrice
      delete data.leaseUnit
      delete data.remark
      // data.housePicList = data.housePicList && data.housePicList.length ? data.housePicList : undefined

      // if (data.leasePrice && (!data.leaseUnit)) {
      //   this.$message('请选择单位')
      //   return false
      // }

      // if (data.leaseUnit && (!data.leasePrice)) {
      //   this.$message('请填写租金金额')
      //   return false
      // }
      // data.leasePrice = data.leasePrice || 0
      // data.leaseUnit = data.leaseUnit || 0
      let blockName = (data.blockName) ? '-' + data.blockName : ''
      let buildingNumber = (data.buildingNumber) ? '-' + data.buildingNumber : ''
      let unit = (data.unit) ? '-' + data.unit : ''
      let room = (data.room) ? '-' + data.room : ''
      let dataObject = `${this.communityName}${blockName}${buildingNumber}${unit}${room}`
      data.dataObject = dataObject

      if (!data.isHaveThirdhouse) {
        data.ncName = ''
        data.ncHouse = ''
      }
      // 添加第三方车位
      if (data.isHaveThirdhouse && data.ncGarages && data.ncGarages.length) {
        data.ncGarages = data.ncGarages.toString()
      } else {
        delete data.ncGarages
      }
      data.isVirtual = data.isVirtual ? 1 : 0

      return true
    },
    async getCommunityRommTypes () {
      const { houseTypeOps } = await getHouseTypeData()
      this.houseTypeOps = houseTypeOps()
      if (!this.form.houseType) {
        let defaultVal = this.houseTypeOps[0] ? this.houseTypeOps[0].value : undefined // eslint-disable-line
        this.$set(this.form, 'houseType', defaultVal)
      }
    },

    // 获取房号的租金单位类型
    getCommunityRoomNumLeaseUnit () {
      this.$axios.get(getCommunityRoomNumLeaseUnitURL).then(res => {
        if (res.status === 100) {
          let { data } = res
          let ops = data.map((val) => {
            return {
              text: val.name,
              value: Number(val.code)
            }
          }) || []
          let tempOps = [
            {
              text: '请选择单位',
              value: undefined
            }
          ]
          this.leaseUnitOps = tempOps.concat(ops)
          // if (!this.form.leaseUnit) {
          //   let defaultVal = ops[0] ? ops[0].value : undefined
          //   this.$set(this.form, 'leaseUnit', defaultVal)
          // }
        }
      })
    },
    communityChange (val) {
      val && val.id && this.getBlockNameList(val.id)
    },
    getBlockNameList (id) {
      this.$axios.get(select2BlockURL, {
        params: { communityId: id }
      }).then(res => {
        if (res.status == 100) { //eslint-disable-line
          let list = res.data || []
          this.blockNameList = list.map(item => {
            return item.name
          })
        }
      })
    },
    blockNameSearch (queryString, cb) {
      let list = this.blockNameList
      let results = queryString ? list.filter(this.createFilter(queryString)) : list
      results = results.map(value => {
        return { value }
      })
      cb(results)
    },
    createFilter (queryString) {
      return (restaurant) => {
        return (restaurant.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    // 保存招商信息 TODO url需要确认
    saveClick () {
      if (this.form.leasePrice && (!this.form.leaseUnit)) {
        this.$message('请选择单位')
        return false
      }

      if (this.form.leaseUnit && (!this.form.leasePrice)) {
        this.$message('请填写租金金额')
        return false
      }
      let params = {
        id: this.form.busiPolicyId,
        houseId: this.form.id,
        // communityBusiId: this.form.communityId,
        housePicList: this.form.housePicList || [],
        // 租金
        leasePrice: this.form.leasePrice || 0,
        // 租金单位
        leaseUnit: this.form.leaseUnit || 0,
        // 招商简介
        remark: this.form.remark || ''
      }
      let _this = this
      this.$axios({
        method: 'PUT',
        url: updateMerchantsInfoURL,
        data: params
      }).then(res => {
        if (res.status === 100) {
          _this.$message.success('保存成功')
          _this.$router.go(-1)
        }
      })
    },
    isVirtualChange (val) {
      this.form.isVirtual = val
    },
    // 添加第三方车位号
    addParkingNumber () {
      if (this.form.ncGarages && this.form.ncGarages.length >= 10) {
        return this.$message.warning('最多添加10个')
      }
      if (!this.form.ncGarages) {
        this.form.ncGarages = []
        this.form.ncGarages.push('')
      } else {
        this.form.ncGarages.push('')
      }
    },
    delParkingNumber (index) {
      this.form.ncGarages.splice(index, 1)
    }
  }
}
</script>
<style lang="scss" scoped>
.validHouseForm-container {

  // overflow: auto;
  .top-tip-wrapper {
    // width: 90%;
    background: #fffbe8;
    display: flex;
    flex-direction: column;

    .red-text {
      margin: 10px;
      color: red;
      font-size: 12px;
    }

    .tip-text {
      margin-left: 10px;
      margin-bottom: 10px;
      font-size: 12px;
    }
  }

  .elform-content-wrapper {
    display: flex;
    justify-content: flex-start;

    .mark-color {
      color: red;
      margin-right: 5px;
    }

    .mark-text {
      margin-right: 5px;
      font-weight: bold;
      color: #333;
      width: 70px;
      flex-shrink: 0;
    }
  }

  .elform-item-wrapper {
    display: flex;
    margin-right: 20px;
    position: relative;
  }

  .elform-item-custom-wrapper {
    display: flex;
    flex-direction: row;
  }

  .tip-text {
    margin-left: 10px;
  }

  .click-more {
    // border-top:1px solid #DCDFE6;
    text-align: center;

    .col2-item {
      padding-left: 80px;
    }

    .btn {
      display: block;
      color: #C0C4CC;
      cursor: pointer;
    }
  }

  .infoSupportType {
    position: absolute;
    left: -22px;
  }

  .parkingNum {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
