var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "merchantInfo" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form },
            scopedSlots: _vm._u(
              [
                _vm.showMerchantsInfo
                  ? {
                      key: "footerSlot",
                      fn: function () {
                        return [
                          _c("v-button", {
                            attrs: {
                              text: "保存",
                              type: "success",
                              disabled: _vm.submitDisabled,
                            },
                            on: { click: _vm.saveClick },
                          }),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "el-form-item",
            { attrs: { label: "图片" } },
            [
              _c("v-uploader", {
                attrs: {
                  action: _vm.uploadURL,
                  imgUrls: _vm.form.housePicList,
                  limit: 12,
                  fileSize: 5,
                },
                on: {
                  "update:imgUrls": function ($event) {
                    return _vm.$set(_vm.form, "housePicList", $event)
                  },
                  "update:img-urls": function ($event) {
                    return _vm.$set(_vm.form, "housePicList", $event)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "tip",
                    fn: function () {
                      return [
                        _c("span", [
                          _vm._v(
                            "第一张图片将作为封面图，每张图片大小不超5M，最多可上传12张图片"
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "租金" } }, [
            _c(
              "div",
              { staticClass: "elform-item-custom-wrapper" },
              [
                _c(
                  "el-form-item",
                  { staticClass: "elform-item-wrapper" },
                  [
                    _c("v-input-number", {
                      attrs: {
                        placeholder: "请填写租金金额",
                        min: 0.01,
                        max: 99999999.99,
                        precision: 2,
                        width: _vm.width,
                      },
                      model: {
                        value: _vm.form.leasePrice,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "leasePrice", $$v)
                        },
                        expression: "form.leasePrice",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c("v-select", {
                      attrs: {
                        options: _vm.leaseUnitOps,
                        width: _vm.width,
                        placeholder: "请选择单位",
                      },
                      model: {
                        value: _vm.form.leaseUnit,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "leaseUnit", $$v)
                        },
                        expression: "form.leaseUnit",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "建筑面积",
                prop: "buildingArea",
                rules: [
                  {
                    required: true,
                    message: "请输入建筑面积",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c(
                "v-input-number",
                _vm._b(
                  {
                    attrs: {
                      disabled: true,
                      placeholder: "请输入建筑面积",
                      width: _vm.width,
                    },
                    model: {
                      value: _vm.form.buildingArea,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "buildingArea", $$v)
                      },
                      expression: "form.buildingArea",
                    },
                  },
                  "v-input-number",
                  _vm.areaNumParams,
                  false
                )
              ),
              _c("field-tips", {
                attrs: { tipsText: "注：与项目房号配置同步" },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "招商简介" } },
            [
              _c("v-textarea", {
                attrs: { placeholder: "请输入简介内容", maxlength: 500 },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }