<template>
  <div class="merchantHouseInfo-container">
    <form-panel
      ref="formPanel"
      :form="form"
      :footerShow="false"
    >
      <col2-detail>
        <col2-block title="房号基础信息">
          <el-form-item
            label="所属项目"
            prop="communityId"
            :rules="[{ required: true, message: '请选择所属项目', trigger: 'change'}]"
          >
            <v-select2 v-model="form.communityId" v-bind="communityParams" placeholder="请选择所属项目" :disabled="!isAdd || showMerchantsInfo" @onChange="communityChange" :width="width"></v-select2>
          </el-form-item>
          <el-form-item
            label="房号信息"
          >
            <div class="elform-content-wrapper">
              <div class="elform-item-wrapper">
                <el-form-item
                >
                  <autocomplete
                   :disabled="showMerchantsInfo"
                    v-model="form.blockName"
                    :fetch-suggestions="blockNameSearch"
                    placeholder="苑"
                    :style="{ width: width + 'px' }"
                    clearable>
                  </autocomplete>
                </el-form-item>
              </div>
              <div class="elform-item-wrapper">
                <span class="mark-color">*</span>
                <el-form-item
                  prop="buildingNumber"
                  :rules="[{ required: true, message: '请输入楼幢/停车场', trigger: 'blur'}]"
                >
                  <v-input :disabled="showMerchantsInfo" v-model="form.buildingNumber" placeholder="楼幢/停车场" :width="width"></v-input>
                </el-form-item>
              </div>
              <div class="elform-item-wrapper">
                <el-form-item>
                  <v-input :disabled="showMerchantsInfo" v-model="form.unit" placeholder="单元/车区" :width="width"></v-input>
                </el-form-item>
              </div>
              <div class="elform-item-wrapper">
                <el-form-item>
                  <v-input :disabled="showMerchantsInfo" v-model="form.room" placeholder="室/车位/车库" :width="width"></v-input>
                </el-form-item>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            label-width="250px"
            label="是否开启和第三方系统房号映射"
          >
            <v-input :disabled="showMerchantsInfo" v-model="form.newwinHouseNum" :width="300" :maxlength="60"></v-input>
          </el-form-item>
          <el-form-item
            label="所属楼层"
            prop="floor"
            :rules="[{ required: true, message: '请输入所属楼层', trigger: 'blur'}]"
          >
            <v-input-number :disabled="showMerchantsInfo" v-model="form.floor" :min="-99" :max="999"  placeholder="请输入所属楼层" :width="width"></v-input-number>
            <span class="tip-text">层/楼</span>
          </el-form-item>
          <el-form-item
            label="是否虚拟"
            prop="isVirtual"
            :rules="[{ required: true, message: '请选择是否虚拟', trigger: 'change'}]"
          >
            <v-select :disabled="showMerchantsInfo" v-model="form.isVirtual" :options="isVirtualOps" :width="width"></v-select>
          </el-form-item>
          <el-form-item
            label="建筑类型"
            prop="buildType"
            :rules="[{ required: true, message: '请选择建筑类型', trigger: 'change'}]"
          >
            <v-select :disabled="showMerchantsInfo" v-model="form.buildType" :options="buildTypeOps" :width="width"></v-select>
          </el-form-item>
          <el-form-item
            label="房屋类型"
            prop="houseType"
            :rules="[{ required: true, message: '请选择房屋类型', trigger: 'change'}]"
          >
            <v-select :disabled="showMerchantsInfo" v-model="form.houseType" :options="houseTypeOps" :width="width"></v-select>
            <span class="tip-text">注：当房屋类型为住宅、公寓、排屋、别墅、商铺、办公用房、经营用房时，才能显示在App中，被用户关联</span>
          </el-form-item>
        </col2-block>
        <col2-block title="房号状态">
          <el-form-item
            label="房屋状态"
            required
          >
            <div class="elform-content-wrapper">
              <div class="elform-item-wrapper">
                <el-form-item
                  prop="roomStatus"
                  :rules="[{ required: true, message: '请选择房屋状态', trigger: 'change'}]"
                >
                  <v-select :disabled="showMerchantsInfo" v-model="form.roomStatus" :width="width" :options="roomStatusOps"></v-select>
                </el-form-item>
              </div>
              <div class="elform-item-wrapper">
                <span class="mark-color">*</span>
                <span class="mark-text">是否可售：</span>
                <el-form-item
                  prop="canSell"
                  :rules="[{ required: true, message: '请选择是否可售', trigger: 'change'}]"
                >
                  <v-select :disabled="showMerchantsInfo" v-model="form.canSell" :width="width" :options="saleTypeOps"></v-select>
                </el-form-item>
              </div>
              <div v-if="form.canSell === 1" class="elform-item-wrapper">
                <span class="mark-color">*</span>
                <span class="mark-text">出售状态：</span>
                <el-form-item
                  prop="isSell"
                  :rules="[{ required: true, message: '请选择出售状态', trigger: 'change'}]"
                >
                  <v-select :disabled="showMerchantsInfo" v-model="form.isSell" :width="width" :options="saleStatusOps"></v-select>
                </el-form-item>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            label="使用状态"
            required
          >
            <div class="elform-content-wrapper">
              <div class="elform-item-wrapper">
                <el-form-item
                  prop="usingStatus"
                  :rules="[{ required: true, message: '请选择使用状态', trigger: 'change'}]"
                >
                  <v-select :disabled="showMerchantsInfo" v-model="form.usingStatus" :width="width" :options="usingStatusOps"></v-select>
                </el-form-item>
              </div>
              <div class="elform-item-wrapper">
                <span class="mark-color">*</span>
                <span class="mark-text">是否可租：</span>
                <el-form-item
                  prop="canLease"
                  :rules="[{ required: true, message: '请选择是否可租', trigger: 'change'}]"
                >
                  <v-select :disabled="showMerchantsInfo" v-model="form.canLease" :width="width" :options="rentTypeOps"></v-select>
                </el-form-item>
              </div>
              <div v-if="form.canLease === 1" class="elform-item-wrapper">
                <span class="mark-color">*</span>
                <span class="mark-text">出租状态：</span>
                <el-form-item
                  prop="isLease"
                  :rules="[{ required: true, message: '请选择出租状态', trigger: 'change'}]"
                >
                  <v-select :disabled="showMerchantsInfo" v-model="form.isLease" :width="width" :options="rentStatusOps"></v-select>
                </el-form-item>
              </div>
            </div>
          </el-form-item>
        </col2-block>
        <col2-block title="其他信息">
          <el-form-item
            label="房屋户型"
          >
            <v-input :disabled="showMerchantsInfo" v-model="form.roomStyle" placeholder="请输入房屋户型" :width="width" :maxlength="30"></v-input>
          </el-form-item>
          <el-form-item
            v-if="!isAdd"
            label="欠费金额"
          >
            <span>{{form.propertyFeeArrearage}}</span>
          </el-form-item>
          <el-form-item
            v-if="!isAdd"
            label="缴纳日期"
          >
            <span>{{propertyFeePaidToDate}}</span>
          </el-form-item>
          <el-form-item
            label="计费面积"
            prop="chargingArea"
            :rules="[{ required: true, message: '请输入计费面积', trigger: 'blur'}]"
          >
            <v-input-number :disabled="showMerchantsInfo" v-model="form.chargingArea" v-bind="areaNumParams" placeholder="请输入计费面积" :width="width"></v-input-number>
          </el-form-item>
          <el-form-item
            label="套内面积"
          >
            <v-input-number :disabled="showMerchantsInfo" v-model="form.innerArea" v-bind="areaNumParams" placeholder="请输入套内面积" :width="width"></v-input-number>
          </el-form-item>
          <el-form-item
            label="分摊面积"
          >
            <v-input-number :disabled="showMerchantsInfo" v-model="form.publicArea" v-bind="areaNumParams" placeholder="请输入分摊面积" :width="width"></v-input-number>
          </el-form-item>
          <el-form-item
            label="辅助面积"
          >
            <v-input-number :disabled="showMerchantsInfo" v-model="form.assistChargingArea" v-bind="areaNumParams" placeholder="请输入辅助面积" :width="width"></v-input-number>
          </el-form-item>
          <el-form-item
            label="花园面积"
          >
            <v-input-number :disabled="showMerchantsInfo" v-model="form.gardenArea" v-bind="areaNumParams" placeholder="请输入花园面积" :width="width"></v-input-number>
          </el-form-item>
          <el-form-item
            label="地下室面积"
          >
            <v-input-number :disabled="showMerchantsInfo" v-model="form.undergroundArea" v-bind="areaNumParams" placeholder="请输入地下室面积" :width="width"></v-input-number>
          </el-form-item>
          <el-form-item
            label="统计面积"
          >
            <v-input-number :disabled="showMerchantsInfo" v-model="form.statisticalArea" v-bind="areaNumParams" placeholder="请输入统计面积" :width="width"></v-input-number>
          </el-form-item>
          <el-form-item
            label="排序"
            prop="sort"
            :rules="[{ required: true, message: '请输入排序', trigger: 'blur'}]"
          >
            <v-input-number :disabled="showMerchantsInfo" v-model="form.sort" :min="0" :max="999"  placeholder="请输入排序" :width="width"></v-input-number>
            <span class="tip-text">注：排序值用于资产剖面图中同层排序，数值越小排列越左</span>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import {
  // saveRoomInfoURL,
  // updateRoomInfoURL,
  getCommunityRommTypesURL,
  queryBlockNameFromCommunityURL
} from '../api'
import {
  roomStatusOps,
  verifyStatusOps,
  isVirtualOps,
  usingStatusOps,
  buildTypeOps,
  // new add
  saleTypeOps,
  saleStatusOps,
  rentTypeOps,
  rentStatusOps
} from '../map'
import { Col2Detail, Col2Block } from 'components/bussiness'
import { Autocomplete } from 'element-ui'
import { normalCommunityParams } from 'common/select2Params'

export default {
  components: {
    Col2Detail,
    Col2Block,
    Autocomplete
  },
  props: {
    responseData: Object
  },
  data () {
    return {
      width: 180,
      saleTypeOps: saleTypeOps(),
      saleStatusOps: saleStatusOps(),
      rentTypeOps: rentTypeOps(),
      rentStatusOps: rentStatusOps(),
      roomStatusOps: roomStatusOps(2),
      statusOps: verifyStatusOps,
      isVirtualOps,
      usingStatusOps: usingStatusOps(2),
      buildTypeOps,
      communityName: '',
      propertyFeePaidToDate: '',
      houseTypeOps: [],
      blockNameList: [],
      form: {},
      communityParams: normalCommunityParams,
      areaNumParams: {
        precision: 2,
        max: 9999.99,
        min: 0
      },
      showMerchantsInfo: false,
      isAdd: false
    }
  },

  created () {
    //
    const { merchants, id } = this.$route.query
    this.isAdd = !id
    if (merchants !== undefined) {
      this.showMerchantsInfo = true
    }
    this.form = this.responseData.form
  },
  mounted () {
    this.getCommunityRommTypes()
  },
  methods: {
    getCommunityRommTypes () {
      this.$axios.get(getCommunityRommTypesURL).then(res => {
        if (res.status == 100) { // eslint-disable-line
          let { data } = res
          let ops = data.map((val) => {
            return {
              text: val.value,
              value: Number(val.code)
            }
          })
          this.houseTypeOps = ops
          if (!this.form.houseType) {
            let defaultVal = ops[0] ?  ops[0].value : undefined // eslint-disable-line
            this.$set(this.form, 'houseType', defaultVal)
          }
        }
      })
    },
    communityChange (val) {
      val && val.id && this.getBlockNameList(val.id)
    },
    getBlockNameList (id) {
      this.$axios.get(queryBlockNameFromCommunityURL, {
        params: { id }
      }).then(res => {
        if (res.status == 100) { //eslint-disable-line
          this.blockNameList = res.data
        }
      })
    },
    blockNameSearch (queryString, cb) {
      let list = this.blockNameList
      let results = queryString ? list.filter(this.createFilter(queryString)) : list
      results = results.map(value => {
        return { value }
      })
      cb(results)
    },
    createFilter (queryString) {
      return (restaurant) => {
        return (restaurant.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.merchantHouseInfo-container {
  .top-tip-wrapper {
    width: 90%;
    background: #fffbe8;
    display: flex;
    flex-direction: column;

    .red-text {
      margin: 10px;
      color: red;
      font-size: 12px;
    }

    .tip-text {
      margin-left: 10px;
      margin-bottom: 10px;
      font-size: 12px;
    }
  }
  .elform-content-wrapper {
    display: flex;
    justify-content: flex-start;

    .mark-color {
      color: red;
      margin-right: 5px;
    }

    .mark-text {
      margin-right: 5px;
      font-weight: bold;
      color: #333;
      width: 70px;
      flex-shrink: 0;
    }
  }

  .elform-item-wrapper {
      display: flex;
      margin-right: 20px;
    }

  .elform-item-custom-wrapper {
    display: flex;
    flex-direction: row;
  }

  .tip-text {
    margin-left: 10px;
  }
}
</style>
